import { QueryFunction } from "react-query";
import { get } from "src/api";
import { AssistantMessageList } from "src/types";

const getMessageList: (
  threadId?: string
) => [string[], QueryFunction<AssistantMessageList>] = (threadId) => [
  ["threadMessages"],
  async () => {
    const result = await get<AssistantMessageList>(
      `/sal-assistant/thread/${threadId}/messages`
    );

    // Check if result is an array before trying to iterate
    return Array.isArray(result) ? [...result].reverse() : [];
  },
];

export { getMessageList };
