import React, { useEffect, useCallback, useState } from "react";
import { Route } from "react-router-dom";
import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import "./richTextStyles.css";
import Portal from "./views/Portal";
import IndexContent from "./views";
import Subscription from "./views/Subscription/Subscription";
import {
  CONTENT,
  EMAIL_VERIFICATION_ROUTE,
  FORGET_RESET_ROUTE,
  FORGET_ROUTE,
  LOGIN_ROUTE,
  LOGIN_SMARTSWAY_ROUTE,
  LOGOUT_ROUTE,
  PAYMENT_DONE_ROUTE,
  REGISTER_ROUTE,
  SETTINGS_ROUTE,
  SUBSCRIPTION_ROUTE,
  PORTAL_ROUTE,
} from "./routes";
import Register from "./views/Register/Register";
import Verify from "./views/Verify/Verify";
import Login from "./views/Login/Login";
import Logout from "./views/Logout/Logout";
import Forget from "./views/Forget/Forget";
import PaymentInfo from "./views/Payment/PaymentInfo";
import LayoutContainer from "./layout/Container";
import Settings from "./views/Settings/Settings";
import AutoLogin from "./views/AutoLogin/AutoLogin";
import Providers from "./Providers";
import { useSelector } from "./store";
import { Center, Spinner } from "@chakra-ui/react";
import RedirectLoading from "./views/RedirectLoading/RedirectLoading";
import "./utils/fetchInterceptor.ts";
import { PlaybackProvider } from "./views/Frequency/SalMagic/components/SalMagicPlayer/PlaybackContext";

const AppContainer = () => {
  const loading = useSelector((state) => state.auth.loading);
  if (loading)
    return (
      <Center minHeight="100vh">
        <Spinner size="xl" />
      </Center>
    );
  return (
    <>
      <Route exact path="/" component={RedirectLoading} />
      <Route path={PORTAL_ROUTE} component={Portal} />
      <Route path={CONTENT} component={IndexContent} />
      <Route path={SUBSCRIPTION_ROUTE} component={Subscription} />
      <Route path={REGISTER_ROUTE} component={Register} />
      <Route path={EMAIL_VERIFICATION_ROUTE} component={Verify} />
      <Route path={LOGIN_ROUTE} component={Login} />
      <Route path={LOGOUT_ROUTE} component={Logout} />
      <Route path={FORGET_ROUTE} component={Forget} exact />
      <Route path={FORGET_RESET_ROUTE} component={Forget.ForgetReset} exact />
      <Route path={PAYMENT_DONE_ROUTE} component={PaymentInfo} />
      <Route path={SETTINGS_ROUTE} component={Settings} />
      <Route path={LOGIN_SMARTSWAY_ROUTE} component={AutoLogin} />
    </>
  );
};
const App = () => {
  return (
    <Providers>
      <PlaybackProvider>
        <LayoutContainer>
          <AppContainer />
        </LayoutContainer>
      </PlaybackProvider>
    </Providers>
  );
};
export default App;
