import {
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  Breadcrumb as ChakraBreadcrumb,
  Text,
  Flex,
  HStack,
} from "@chakra-ui/react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, Link as ReactRouterLink } from "react-router-dom";
import SalMagicGate from "src/views/Frequency/SalMagic/components/SalMagicGate";
import SectionHeader from "../SectionHeader/SectionHeader";

interface Props {
  smallHeader?: any;
  breadTitle?: string;
}

const Breadcrumb: React.FC<Props> = ({ smallHeader, breadTitle }) => {
  const location = useLocation();
  return (
    <ChakraBreadcrumb color="gray.900" textTransform="capitalize" separator=" ">
      <BreadcrumbItem w="100%">
        <BreadcrumbLink
          as={ReactRouterLink}
          to={`/${location.pathname.split("/")[1]}/${
            location.pathname.split("/")[2]
          }`}
        >
          <FormattedMessage id={location.pathname.split("/")[2]} />
        </BreadcrumbLink>
        {location.pathname.includes("waves") && (
          <>
            &nbsp;-&nbsp;
            <BreadcrumbLink
              as={ReactRouterLink}
              to={`/${location.pathname.split("/")[1]}/${
                location.pathname.split("/")[2]
              }/${location.pathname.split("/")[3]}`}
            >
              <FormattedMessage id={location.pathname.split("/")[3]} />
            </BreadcrumbLink>
          </>
        )}

        {location.pathname.split("/").length > 4 &&
          !location.pathname.includes("waves") && (
            <>
              &nbsp;-&nbsp;
              <BreadcrumbLink
                as={ReactRouterLink}
                to={`/${location.pathname.split("/")[1]}/${
                  location.pathname.split("/")[2]
                }/${location.pathname.split("/")[3]}`}
              >
                <FormattedMessage id={location.pathname.split("/")[3]} />
              </BreadcrumbLink>
            </>
          )}
      </BreadcrumbItem>
      <BreadcrumbItem isCurrentPage w="100%">
        {location.pathname.includes("waves") ? (
          location.pathname.includes("category") ? (
            <Heading as="h2" textStyle={"h2"} fontFamily={"Calibri (Body)"}>
              <Text fontFamily={"Calibri (Body)"}>{breadTitle}</Text>
            </Heading>
          ) : (
            <Flex w="100%" justifyContent="space-between" alignItems="start">
              <SectionHeader slug={breadTitle ? breadTitle : " "} />
              <SalMagicGate radius={105} />
            </Flex>
          )
        ) : location.pathname.startsWith("/content/communication/symptoms/") &&
          location.pathname !== "/content/communication/symptoms/male" &&
          location.pathname !== "/content/communication/symptoms/female" ? (
          <Heading as="h2" textStyle={"h2"} fontFamily={"Calibri (Body)"}>
            <BreadcrumbLink
              as={ReactRouterLink}
              to={`/${location.pathname.split("/")[1]}/${
                location.pathname.split("/")[2]
              }/${location.pathname.split("/")[3]}/${
                location.pathname.split("/")[4]
              }`}
            >
              <FormattedMessage
                id={
                  location.pathname.split("/")[4] ||
                  location.pathname.split("/")[2]
                }
              />
            </BreadcrumbLink>
          </Heading>
        ) : breadTitle ? (
          <Heading as="h2" textStyle={"h2"} fontFamily={"Calibri (Body)"}>
            <Text fontFamily={"Calibri (Body)"}>{breadTitle}</Text>
          </Heading>
        ) : location.pathname.split("/").length <= 4 ? (
          <SectionHeader slug={location.pathname.split("/")[3]} />
        ) : (
          isNaN(+location.pathname.split("/")[4]) && (
            <Heading as="h2" textStyle={"h2"} fontFamily={"Calibri (Body)"}>
              <Text fontFamily={"Calibri (Body)"}>
                <FormattedMessage
                  id={breadTitle ? breadTitle : location.pathname.split("/")[4]}
                />
              </Text>
            </Heading>
          )
        )}

        {smallHeader ? smallHeader : null}
      </BreadcrumbItem>
    </ChakraBreadcrumb>
  );
};
export default Breadcrumb;
