import React, { useState, useEffect } from "react";
import { Flex, Center, Box } from "@chakra-ui/react";
import AssistantMessage from "./AssistantMessage";
import { useSelector } from "src/store";
import Input from "./Input";
import ProgramSelector from "./ProgramSelector";
import Messages from "./Messages";
import { AssistantMessageList, OpenAiMessage } from "src/types";
import { getMessageList } from "src/api/assistant/query";
import UserMessage from "./UserMessage";
import { submitMessage } from "src/api/assistant/mutation";
import { BASE_URL } from "src/configs/config";
import { isTokenExpired } from "src/utils/fetchInterceptor";
import { get } from "src/api";
import { PropagateLoader } from "react-spinners";
import { useQuery } from "react-query";
import { useIntl } from "react-intl";

const AssistantChat: React.FC<{}> = () => {
  const [messages, setMessages] = useState<JSX.Element[]>([]);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const { formatMessage } = useIntl();

  const user = useSelector((state) => state.auth.user);
  const { focusProgram, isChatOpen } = useSelector((state) => ({
    focusProgram: state.assistant.focusProgram,
    isChatOpen: state.assistant.isChatOpen,
  }));

  const {
    data: chatHistory,
    isLoading,
    error,
  } = useQuery<AssistantMessageList, Error>(
    ...getMessageList(user?.assistant_thread_id)
  );

  let sse: EventSource | undefined;

  if (error) {
    console.error("Error fetching chat history:", error);
    setIsInputDisabled(false);
  }

  useEffect(() => {
    if (chatHistory) {
      const history = chatHistory.map((message: OpenAiMessage, index) => (
        <React.Fragment key={message.id}>
          {message.content.map((contentItem: any, contentIndex: number) => (
            <React.Fragment key={`${message.id}-${contentIndex}`}>
              {message.role === "user" ? (
                <UserMessage
                  key={`${message.id}-${contentIndex}`}
                  text={contentItem.text.value}
                />
              ) : (
                <AssistantMessage
                  key={`${message.id}-${contentIndex}`}
                  text={contentItem.text.value}
                />
              )}
            </React.Fragment>
          ))}
        </React.Fragment>
      ));
      setMessages(history);
    }
  }, [chatHistory]);

  const initSse = async () => {
    if (isTokenExpired(window.localStorage.getItem("__sal_auth"))) {
      console.log(
        "Access token expired, calling api for refresh process to kick-in."
      );
      await get("/user/me");
    }

    // Create an EventSource object
    const sse = new EventSource(
      `${BASE_URL}/sal-assistant/stream?token=${window.localStorage.getItem(
        "__sal_auth"
      )}`
    );

    sse.onerror = (error) => {
      console.error("Error:", error);
      sse!.close();
    };

    sse.onmessage = (event) => {
      const { name, message: rMessage } = JSON.parse(event.data);

      if (name === "messageCreated") {
        setIsInputDisabled(false);
        setMessages((prevMessages) => {
          if (prevMessages.some((message) => message.key === rMessage.id))
            return prevMessages;
          return [
            ...prevMessages,
            <AssistantMessage
              key={rMessage.id}
              messageId={rMessage.id}
              eventSource={sse}
              text={""}
            />,
          ];
        });

        setIsInputDisabled(false);
      }
    };
  };

  const send = async (
    message: string,
    role: "user" | "assistant" = "user"
  ): Promise<void> => {
    if (!message || message === "") return;
    setIsInputDisabled(true);

    // Append user message to the existing messages list
    const userMessage = [
      ...messages,
      <UserMessage key={messages.length + 1} text={message} />,
    ];
    setMessages(userMessage);
    // Send the message to the backend
    if (focusProgram) {
      try {
        await initSse();
        await submitMessage(message, role, focusProgram);
      } catch (error) {
        console.error("SalAssistant", error);
      }
    }
  };
  return (
    <Flex
      flexDir="column"
      position="absolute"
      top={0}
      right={0}
      width="100%"
      height="100%"
      bg="white"
      transform={`translateX(${isChatOpen ? "0" : "100%"})`}
      transition="transform 0.3s ease-in-out"
      p={2}
      visibility={isChatOpen ? "visible" : "hidden"}
    >
      <Box overflowY="auto">
        {!chatHistory?.length && !focusProgram && isChatOpen && (
          <AssistantMessage
            text={
              formatMessage({ id: "sal_assistant.user_welcome_message" }) +
              user?.name.split(" ")[0] +
              "?"
            }
          />
        )}
        {isLoading ? (
          <Center h="100%">
            <PropagateLoader color="#2895FF" />
          </Center>
        ) : (
          <Messages messages={messages} />
        )}
      </Box>
      {focusProgram ? (
        <Input onSend={send} isDisabled={isInputDisabled} />
      ) : (
        <ProgramSelector />
      )}
    </Flex>
  );
};

export default AssistantChat;
